* {
	margin: 0;
	padding: 0;

	&, &:before, &:after {
		box-sizing: inherit;
	}
}

::selection {}
::-moz-selection {}

// DO NOT set font-size and line-height here!
// Adjust $base-font-size and $base-line-height in _config.scss
html {
	background: $light;
	box-sizing: border-box;
	color: $dark;
	font-family: $main-font;
	font-size: 100% * ($base-font-size / 16px);
	hyphens: manual;
	line-height: rem($base-line-height);

	/* disable text resize in landscape. e.g. on iphone */
	text-size-adjust: none;
}

body {
	line-height: rem($base-line-height);

	// Debug mode
	&.the-customer-page #log_hider {
		display: none;
	}

	&.ie-8 #pageloader:after {
		display: none;
	}
}

iframe {
	border:none;
	width: 100%;
}

/**
 * General Margin
 */
p, ol, dl, .margin-bottom {
	margin-bottom: rem($base-line-height);
}

/**
 * Headlines
 */

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
	@extend .margin-bottom;
	display: block;
	text-rendering: optimizeLegibility;
	text-align: center;
}

h1, .h1 {
	
	font-family: $display-font;
	font-size: rem($h1-size);
	line-height: 1em;
	margin-bottom: 1rem; 
	
}

h2, .h2 {
	font-size: rem($h2-size);
	line-height: 1.3em;
}

h3, .h3 {
	font-size: rem($h3-size);
}

h4, .h4 {
	font-size: rem($h4-size);
}

h5, .h5 {
	font-size: rem($h5-size);
}

h6, .h6 {
	font-size: rem($h6-size);
}

/**
 * Links
 */
a {
	color: $primary;

	&:focus, &:hover, &:active {
		color: $secondary;
	}

	img {
		border: none;
	}

	&[href$=".pdf"]:before {
		@extend .fa-default;
		@extend .fa-file-pdf-o;
		margin-right: 0.5em;
	}

	&[href^=tel] {
        color:inherit;
        text-decoration:underline;
    }
}

hr {
	background: $medium;
	border: none;
	clear: both;
	height: rem(1px);
	margin: 1rem 0;
	flex-basis:100%;
}

ul {
	list-style: none;

	&.styled-list {
		@extend .margin-bottom;

		li {
			padding-top: .3rem;
			padding-bottom: .3rem;
			position: relative;
			margin-left: 1.5rem;

			&:before {
				content: "»";
				line-height: 1.5rem;
				margin-right: .5rem;
				position: absolute;
				left: -25px;
			}
		}

		ul {
			margin-left: 1rem;
			margin-bottom: 0;
		}
	}

	&.unstyled-list {
		margin-bottom:0;

		li {
			padding-bottom:0;
			padding-top:0;

			&:before {
				content: none;
			}
		}
	}
}

ol {
  list-style-type: none;
  counter-reset: item;

  li {
	padding-top: .3rem;
	padding-bottom: .3rem;
	display: table;
	counter-increment: item;

		&:before {
			content: counters(item, ".") ". ";
			display: table-cell;
			padding-right: 0.3em;
		}
	}

	ol {
		margin-bottom: 0;

		li {

			&:before {
				content: counters(item, ".") " ";
			}
		}
	}
}

/*
 * responsive images
 */

img {
	vertical-align: bottom;
    float:none;
    height:auto;
    max-width:100%;
    width:100%;

    &[src^='http://cms.'], &[src^='https://cms.'] {
		max-width:none;
		width:auto;
	}

    [data-rel] &, .lightbox-image & {
        margin-bottom:0;
    }
}

/*
 * reset copyright link
 */
[href*='alpen-web.ch'],
[href*='bewertet.de'],
[href*='euroweb.at'],
[href*='euroweb.de'],
[href*='ew.de'],
[href*='geprueft.de'],
[href*='web2walk.com'],
[href*='webstyle.de'],
[href*='webstyle.com'],
[href*='wn-onlineservice.de'],
[href*='internet-media.com'],
[href*='yourrate.com'] {
    display:table;
    text-decoration:none;

    img {
        background:none;
        border-radius:0;
        border:none;
        margin-bottom:5px;
        outline:none;
        padding:0;
        white-space:normal;
        width:auto !important;
    }
}

/**
 * Tables
 */
table {
	@extend .margin-bottom;
	border-collapse: collapse;
	width: 100%;

	caption {
		font-size: 1.2rem;
		font-weight: 700;
		padding-bottom: .5rem;
	}
}

// Responsive table styles
table, thead, tbody, th, td, tr, caption {
	display: block;
}

thead {
	border-collapse: collapse;

	tr {
		position: absolute;
		top: -999%;
		left: -999%;
	}
}

tbody tr {
	border-bottom: 1px solid $medium;
	padding: rem($base-gap) 0;
}

td {
	min-height: 1rem;
	padding: .3rem rem($base-gap) .3rem 35%;
	position: relative;

	&:before {
		content: attr(data-label) ": ";
		font-weight: bold;
		left: 1rem;
		position: absolute;
		top: .3rem;
		white-space: nowrap;
		width: 45%;
	}
}

// Restore proper table display values for larger screens
@include breakpoint(small) {
	table {
		display: table;
	}

	caption {
		display: table-caption;
	}

	thead {
		display: table-header-group;
	}

	tbody {
		display: table-row-group;
	}

	tr {
		display: table-row;
	}

	th, td {
		display: table-cell;
		vertical-align: top;
	}

	td {
		min-height: inherit;
		padding: rem($base-gap) 1rem;

		&:before {
			display: none;
			content: "";
			width: auto;
		}
	}

	thead tr {
		position: static;
		left: auto;
		top: auto;
	}
}
