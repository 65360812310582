// Calculates grid with for column classes
@mixin grid-columns($breakpoint, $columns: $column-names) {
    $column-count: length($column-names);
    $col-width: 0;

    .row{
        &.#{$breakpoint}-start {
            justify-content: flex-start;
        }
        &.#{$breakpoint}-center {
            justify-content: center;
        }
        &.#{$breakpoint}-end {
            justify-content: flex-end;
        }
        &.#{$breakpoint}-stretch {
            align-items: stretch;
        }
        &.#{$breakpoint}-top {
            align-items: flex-start;
        }
        &.#{$breakpoint}-middle {
            align-items: center;
        }
        &.#{$breakpoint}-bottom {
            align-items: flex-end;
        }
        &.#{$breakpoint}-between {
            justify-content: space-between;
        }
        &.#{$breakpoint}-around {
            justify-content: space-around;
        }
        &.#{$breakpoint}-reverse {
            flex-direction: row-reverse;
        }
        &.#{$breakpoint}-forward {
            flex-direction: row;
        }
    }
    .col{

        @for $i from 1 through $column-count {
            $col-width: 100% / ($column-count / $i);

            &.#{$breakpoint}-#{nth($column-names, $i)} {
                max-width: $col-width;
                flex-basis: $col-width;
            }

            &.#{$breakpoint}-prefix-#{nth($column-names, $i)} {
                margin-left: $col-width;
            }

            &.#{$breakpoint}-suffix-#{nth($column-names, $i)} {
                margin-right: $col-width;
            }
            &.#{$breakpoint}-min-#{nth($column-names, $i)}{
                min-width: $col-width;
            }
        }

        &.#{$breakpoint}-prefix-0 {
            margin-left: 0;
        }
        &.#{$breakpoint}-suffix-0 {
            margin-right: 0;
        }
        &.#{$breakpoint}{
            flex-basis: 0;
        }
        &.#{$breakpoint}-first {
            order: -1;
        }
        &.#{$breakpoint}-last {
            order: 1;
        }
        &.#{$breakpoint}-unset {
            order: 0;
        }
    }
}


/**
 * Grid system
 */

.row {
    margin-left:auto;
    margin-right:auto;
    max-width: rem($row-max-width);
    width: 100%;
    position: relative;
    display: flex;
    flex: 0 1 auto;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    &.start {
        justify-content: flex-start;
    }
    &.center {
        justify-content: center;
    }
    &.end {
        justify-content: flex-end;
    }
    &.top {
        align-items: flex-start;
    }
    &.middle {
        align-items: center;
    }
    &.bottom {
        align-items: flex-end;
    }
    &.between {
        justify-content: space-between;
    }
    &.around {
        justify-content: space-around;
    }
    &.reverse {
        flex-direction: row-reverse;
    }
    &.forward {
        flex-direction: row;
    }
    &.full{
        max-width: none;
    }

    .row {
        margin-left:-#{rem($base-gap)};
        padding-left:0;
        padding-right:0;
        max-width: calc(100% + #{rem($base-gap*2)});
        width: calc(100% + #{rem($base-gap*2)});
    }

    >.row {
        margin: 0;
        padding: 0;
        max-width: 100%;
        width: 100%;
        flex-basis: 100%;
    }
}

// Initialize grid
.col {
    float: left;
    padding-left: rem($base-gap);
    padding-right: rem($base-gap);
    max-width:100%;
    width: 100%;
    flex: 0 0 auto;
    flex-grow: 1;
    flex-basis: 100%;
   
    &.first {
        order: -1;
    }
    &.last {
        order: 1;
    }
    &.unset {
        order: 0;
    }
}

// Grid classes for default breakpoint (no min-width)
@include grid-columns(default);

// Generate column classes
@each $point, $width in $breakpoints {
    @include breakpoint($point) {
        @include grid-columns($point);
    }
}
