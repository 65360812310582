// Styled list
dl.floatList {
    @include pie-clearfix;

    dt, dd {
        display: block;
        vertical-align: top;
        float: left;
    }

    dt {
        padding-right: .5rem;
        width: 40%;
        clear: left;
        margin-bottom: 1rem;
        @include breakpoint (medium) {
            margin-bottom: 0rem;
        }
    }

    dd {
        width: 60%;
        margin-bottom: 1rem;
        @include breakpoint (medium) {
            margin-bottom: 0rem;
        }
    }
}

// SOME HELPFUL CLASSES
//////////////////////////////

.highlight {
    color: $secondary;
}

.btn {
    background: $secondary;
    border: none;
    color: $dark;
    cursor: pointer;
    display: table;
    font-size: rem(16px);
    line-height: 1rem;
    width: 100%;
    padding: rem(20px);
    text-align: center;
    color: $light;
    text-decoration: none;
    transition: 300ms all ease-in-out;

    &:hover, &:active, &:focus {
        background: $primary;
        color: $light;
    }

    @include breakpoint(medium) {
        font-size: 18px;
    }


    *[class^="icon"] {
        margin-left: .5rem;
        vertical-align: middle;
    }
}

.notification {
    padding: 1em;
    background: $alert;
    color: $light;
    font-size: 1.2em;
}

button,
.button {
    background: $secondary;
    color: $light;
    padding: 20px;
    width: 100%;
    border: none;
    font-family: 'Ubuntu', sans-serif;
    font-size: 14px;

    @include breakpoint(medium) {
        font-size: 18px;
    }


    &:hover {
        background: $primary;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;
    }
}

.no-padding {
    padding: 0;
}

// PAGE WRAP
//////////////////////////////

.page-wrap {
    padding-top: rem(0px);

    @include breakpoint(medium) {
        padding-top: 0;
    }
}

// HEADER
//////////////////////////////

.header {
    background: $light;
    position: relative;
    padding: rem(10px) rem($base-gap);
    width: 100%;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    height: 0;

   
        
}

.header-pic {
    background: none;

    @include breakpoint(medium) {
        background: url("../images/header.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        padding:5rem 0;
    }
     @include breakpoint(giant) {
           margin-bottom: 0px; 
        }


    body.index &{
        @include breakpoint(medium) {
            padding: 5rem 0;
            
        }

        // @include breakpoint(large) {
        //     padding: 3rem 0;
        // }


        @include breakpoint(giant) {
            padding: 10rem 0;
        }
    }

    .hs {
        width: 100%;
        position: relative;
        display: flex;
        flex-flow: column;
        align-items: center;
             @include breakpoint(giant) {
            margin-bottom: 0;
        }

        .btn{
            font-size: 16px;
            margin: 1rem 0 0 0;
            position: relative;
            bottom: -18px;
            line-height: 25px;
            padding: 13px;

            @include breakpoint(large) {
                    line-height: 15px;
                    padding: rem(20px);
            }

            span{
                display: block;
                text-align: center;
                @include breakpoint(large) {
                    display: inline-block;
                }
            }
        }

        .branding {
            width: 70%;
            position: relative;
            display: block;
            left: 50%;
            transform: translateX(-50%);
            top: 12px;
        }
    }
}

.claim {
    background: $primary;
    color: $light;
    padding: 20px;
    margin-top: 90px;  

    .claim-list {
        @include breakpoint(medium) {
            // display: inline-flex;
        }


        li {
            padding: 0 21px;
            float: left;

            &:before {
                font-family: $icon-font;
                content: "\f00c";
                margin-right: 10px;
            }
        }
    }

    .claim-border {
        padding: 20px;
        outline-offset: -1px;
        outline: 1px solid $border;
    }

    body.index & {
        margin-top:  4rem;

        @include breakpoint(large) {
            margin-top: 0;
        }
    }
}

.phone-claim {
    margin-top: 6px;
    padding: 1rem 0 1rem 0;

    @include breakpoint(medium) {
        margin-bottom: 50px;
        background: rgba(255,255,255,.7);
    }


    .btn {
        &:hover {
            background: $secondary;
        }
    }
}

.flex-0 {
    @include breakpoint(medium) {
        -webkit-flex-basis: 100%;
        flex-basis: 100%;
    }


    -webkit-flex-basis: 0;
    flex-basis: 0;
}

// TEASER
//////////////////////////////

.teaser {
    margin-bottom: 50px;
    include breakpoint(medium) {
        margin-bottom: 100px;
        margin-top:  0;    
    }

    .teaser-circle {
        width: 140px;
        height: 140px;
        background: #fff;
        -moz-border-radius: 70px;
        -webkit-border-radius: 70px;
        border-radius: 70px;
        margin: 0 auto;
        position: relative;
        top: 70px;
        -webkit-box-shadow: 0px -25px 69px -39px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: 0px -25px 69px -39px rgba(0, 0, 0, 0.75);
        box-shadow: 0px -25px 69px -39px rgba(0, 0, 0, 0.75);

        @include breakpoint(small) {
            width: 120px;
            height: 120px;
        }


        @include breakpoint(medium) {
            width: 140px;
            height: 140px;
        }


        &:before {
            display: flex;
            justify-content: center;
            position: relative;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .karo {
        &:before {
            content: url("/images/karosserie-icon.png");
        }
    }

    .lack {
        &:before {
            content: url("/images/lackierung-icon.png");
        }
    }

    .kfz {
        &:before {
            content: url("/images/kfz-icon.png");
        }
    }

    .btn {
        margin-top: 6px;
        text-align: center;

        a {
            color: $light;
        }
    }

    a {
        text-decoration: none;

        &:hover {
            color: $light;
        }
    }
}

// MAIN CONTENT
//////////////////////////////

main {
    display: block;
    padding-bottom: 90px;
    margin-top: 50px;
    
    include breakpoint(medium) {
        margin-top:  0;    
    }
    .index {
        padding-top: 90px;
    }

    ul {
        @extend .styled-list;
    }

    .google-maps {
        @extend .video-container;

        @include breakpoint(medium) {
            height: rem(400px);
        }
    }

    .opening-img {
        background: url("../images/index/oeffnungszeiten.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        color: $light;
        padding: 25px 10px 10px 25px;
        line-height: 18px;
        font-size: 14px;
        margin-bottom: 20px;

        @include breakpoint(medium) {
            height: 370px;
            display: flex;
            font-size: 18px;
            -webkit-align-items: flex-end;
            align-items: flex-end;
            padding: 20px;
            outline-offset: -12px;
            outline: 1px solid #d9d9d9;
        }


        p {
            margin-bottom: 0.8rem;
        }

        h3 {
            text-align: left;
        }
    }

    .gallery {
        display: none;
        margin: 90px 0;

        @include breakpoint(medium) {
            display: block;
        }


        h3 {
            text-align: left;
        }

        .btn {
            margin-top: (35px,);
        }
    }
}

// FOOTER
//////////////////////////////

.footer {
    display: block;

    .maps {
        margin-bottom: 30px;
        height: rem(345px);
    }

    img {
        width: auto;
    }
}

/* Slider */

.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
    padding: 0 2rem;

    .slick-prev{
        display: inline-block;
        position: absolute;
        width: 20px;
        top:35%;
        left: -20px;
        background: none;
        font-size: 28px;

        &:before{
            content:'\f104';
            font-family: 'FontAwesome';
            color: $dark;
        }

    }

    .slick-next{
        display: inline-block;
        position: absolute;
        width: 20px;
        top:35%;
        right: -15px;
        background: none;
        font-size: 28px;

        &:before{
            content:'\f105';
            font-family: 'FontAwesome';
            color: $dark;
        }

    }

}
.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;

    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}
.slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    [dir="rtl"] & {
        float: right;
    }
    img {
        display: block;
    }
    &.slick-loading img {
        display: none;
    }

    display: none;

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}
.slick-arrow.slick-hidden {
    display: none;
}

.audio-player {
    h4 {
        text-align: left;
    }
}
body.impressum, .datenschutzerklaerung {
    word-break: break-word;
}