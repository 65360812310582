/*=NAVIGATION MAIN */
.navi-main {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include breakpoint(large) {
        flex-direction: row;

        // height: 4.25rem;
        background: $primary;
        padding: 0;
    }


    li {
        display: inline-block;
        position: relative;
        transition: all 0.25s ease;

        &.anfahrt, &.kontakt, &.impressum, &.datenschutzerklaerung, &.ueber-uns {
            display: block;

            @include breakpoint(large) {
                display: block;
            }


            a {
                color: $light;
                background: $primary;

                &:hover, &:active {
                    background: $secondary;
                }
            }
        }

        &.facebook {
            background: url(/images/facebook.png) no-repeat center center;

            // @include breakpoint(large){
            //     background: url(/images/facebook.png) no-repeat center 63%;
            // }
            &:hover {
                background: $secondary;
                background: url(/images/facebook.png) no-repeat center center;
            }

            a {
                color: #3b579d;
                font-weight: 300;

                @include breakpoint(large) {
                    font-size: 0;
                    color: $light;
                }
            }
        }

        &:hover, &:active {
            @include breakpoint(large) {
                background: $secondary;

                > a, > span {
                    color: $light;
                    

                }

                > .sub {
                    height: 100%;
                    overflow: visible;
                    transition: all 0s ease;

                    > li {
                        transform: translateX(0);
                        opacity: 1;

                        &:nth-child(1) {
                            transition-delay: 0.0s;
                        }

                        &:nth-child(2) {
                            transition-delay: 0.1s;
                        }

                        &:nth-child(3) {
                            transition-delay: 0.2s;
                        }

                        &:nth-child(4) {
                            transition-delay: 0.3s;
                        }

                        &:nth-child(5) {
                            transition-delay: 0.4s;
                        }

                        &:nth-child(6) {
                            transition-delay: 0.5s;
                        }

                        &:nth-child(7) {
                            transition-delay: 0.6s;
                        }

                        &:nth-child(8) {
                            transition-delay: 0.7s;
                        }

                        &:nth-child(9) {
                            transition-delay: 0.8s;
                        }

                        &:nth-child(10) {
                            transition-delay: 0.9s;
                        }

                        &:nth-child(11) {
                            transition-delay: 1s;
                        }

                        &:nth-child(12) {
                            transition-delay: 1.1s;
                        }

                        &:nth-child(13) {
                            transition-delay: 1.2s;
                        }
                    }
                }
            }
        }


            a {
                color: $light;
        &.active {
            background: $secondary;
            }
        }

        a, span {
            cursor: pointer;
            text-align: left;
            text-decoration: none;
            width: 100%;
            font-weight: 100;
            display: flex;
            align-items: center;
            height: rem(60px);
            font-size: rem(18px);
            position: relative;
            color: $light;
            padding: 0 1rem;
            transition: all 0.25s ease;

            @include breakpoint(large) {
                // height: rem(80px);
                text-align: center;
                padding: 0 2.75rem;
            }


            @include breakpoint(giant) {
                font-size: rem(18px);
            }
        }

        span {
            cursor: default;
        }

        // last child of navigation with sub
        &:last-child {
            .sub {
                right: 0;
                left: auto;
                transform: translateX(0);

                @include breakpoint(giant) {
                    right: auto;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
        }
    }

    .hasSub {
        position: relative;
        background: none;

        @include breakpoint(large) {
            border: none;
        }


        > a, > span {
            background: $primary;

            @include breakpoint(large) {
                background: none;
            }
        }

        &.active {
            a{
                  background: $primary;
            }
            > a, > span {
                color: $light;
                background: $primary;
            }
        }
    }

    .sub {
        display: block;
        overflow: visible;
        position: relative;
        white-space: nowrap;
        height: auto;
        min-width: 100%;
        left: 50%;
        transform: translateX(-50%);
        transition: height 0.25s ease;

        @include breakpoint(large) {
            overflow: hidden;
            height: 0px;
            position: absolute;
        }


        &.active {
            flex-wrap: wrap-revers;
        }

        .sub {
            @include breakpoint(large) {
                left: 100%;
                transform: translateX(0);
                top: 0;
            }
        }

        > li {
            display: block;

            @include breakpoint(large) {
                opacity: 0;
                transition: all 0.4s ease;
                transform: translateX(-20%);
                transition-delay: 0.25s;

                &:nth-child(2n) {
                    transform: translateX(20%);
                }

                &:last-child a {
                    border-bottom: 1px solid $secondary;
                }
            }


            &:after {
                display: none;
            }

            a {
                height: 3rem;
                color: $primary;
                background: none;
                padding: 0 1.5rem;
                cursor: pointer;
                border-left: 2px dotted $medium;

                @include breakpoint(large) {
                    color: $light;
                    background: $secondary;
                    border-right: 1px solid $secondary;
                    border-left: 1px solid $secondary;
                }


                &:hover, &:active {
                    border-left: 2px dotted $primary;

                    @include breakpoint(large) {
                        border-left: 1px solid $secondary;
                        background: $primary;
                        color: $light;
                    }
                }

                &.active {
                    border-left: 2px dotted $light;
                    color: $light;
                    background: $secondary;

                    @include breakpoint(large) {
                        border-left: 1px solid $light;
                    }
                }
            }
        }
    }
}

.noSub {
    li {
        .sub {
            display: none;
        }
    }
}

// Off-canvas navigation
.page-wrap {
    margin-top: 0;

    @include breakpoint(large) {
        margin-top: 1rem;
    }
}

.page-navi {
    background: $light;
    min-height: 100vh;
    transform: translateY(-100%);

    @extend %animated-transform;

    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 100;

    @include breakpoint(large) {
        transform: translateY(0);
        transition: none;
        position: fixed;
        min-height: inherit;
        border-bottom: 1px solid $dark;
    }


    .page-nav-logo {
        width: auto;
        margin: 0 auto;
    }

    .brandingMenu {
        display: block;
        width: 100%;
        text-align: center;
        background: $light;
        padding: 0.5rem 0;

        @include breakpoint(large) {
            display: none;
        }
    }

    .ctaNav {
        width: 100%;
        position: relative;
        float: left;

        @include breakpoint(large) {
            display: none;
        }


        a, .mobileButton {
            display: block;
            color: $light;
            background: $secondary;
            padding: 10px 0;
            height: 45px;
            width: 100%;
            text-align: center;
            margin-bottom: 5px;

            @include breakpoint(large) {
                display: none;
            }


            i {
                font-size: 1.625rem;
            }

            p {
                font-size: 1rem;
                margin: -5px 0 0 15px;
                display: inline-block;
                color: $light;
                transform: translateY(-4px);
            }
        }
    }
}

.iexplore {
    .navi-add {
        display: none;

        @include breakpoint(medium) {
            display: block;
            font-weight: 400;
            height: auto;
            width: 500px;
            margin: 0 auto;
        }


        li {
            display: inline-block;
        }
    }
}

.navi-add {
    display: none;

    @include breakpoint(large) {
        display: flex;
        justify-content: center;
        font-weight: 400;
        flex-direction: row;
        margin: 0 auto;
        margin-bottom: 50px;
        background: $primary;
        width: 100%;
    }


    li {
        margin-left: 1rem;

        &.kontakt, &.anfahrt {
            display: block;

            @include breakpoint(giant) {
                display: block;
                text-align: center;
            }
        }

        a {
            color: $light;
            font-weight: 300;
            transition: all 0.25s ease;
            cursor: pointer;
            text-align: left;
            text-decoration: none;
            width: 100%;
            font-weight: 100;
            display: flex;
            align-items: center;
            height: rem(60px);
            font-size: rem(18px);
            position: relative;

            padding: 0 1rem;
            transition: all 0.25s ease;

            @include breakpoint(large) {
                // height: rem(80px);
                text-align: center;
                padding: 0 2.75rem;
            }


            @include breakpoint(giant) {
                font-size: rem(18px);
            }

            @include breakpoint(giant) {
                font-size: rem(18px);
                text-decoration: none;
            }


            &:hover, &:active {
                color: $medium;
                background: $secondary;
            }
        }
    }
}

// Mobile Navi

.iconMenu {
    float: left;
    height: 25px;

    // top: 0.875rem;
    position: relative;
    width: 100%;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    transition: all 0.25s ease;
    margin-bottom: 1rem;
    height: 50px;
    padding: 0.75rem 0;

    @include breakpoint(large) {
        display: none;
    }


    span {
        display: block;
        height: 3px;
        width: 30px;
        background: $medium;
        position: relative;
        opacity: 1;
        transition: all 0.25s ease;
    }

    &:before {
        content: "";
        display: block;
        height: 3px;
        width: 30px;
        background: $medium;
        position: relative;
        transform: rotate(0);
        transition: all 0.25s ease;
    }

    &:after {
        content: "";
        display: block;
        height: 3px;
        width: 30px;
        background: $medium;
        position: relative;
        transform: rotate(0) translate(0);
        transition: all 0.25s ease;
    }

    &:hover {
        &:before {
            background: $medium;
        }

        &:after {
            background: $medium;
        }

        span {
            background: $medium;
        }
    }
}

#nav-checkbox {
    display: none;

    &:checked {
        ~ .page-navi {
            transform: translateY(0);
            top: 50px;
        }

        ~ .page-wrap {
            transform: translateX(0%);
            position: fixed;
            width: 100%;
            z-index: -1;
        }

        ~ .iconMenu {
            width: 100%;
            background: $light;
            left: 0;
            top: 0;
            justify-content: center;

            span {
                opacity: 0;
                background: $primary;
            }

            &:before {
                transform: rotate(45deg);
                background: $primary;
            }

            &:after {
                transform: rotate(-45deg) translate(4px, -4px);
                background: $primary;
            }
        }

        @include breakpoint(large) {
            ~ .page-navi {
                transform: translateY(0);
                top: 0;
            }

            ~ .page-wrap {
                transform: translateX(0);
                position: relative;
            }
        }
    }
}

.desktop-nav {
    display: none;

    @include breakpoint(large) {
        display: block;
        margin-bottom: 75px;
        z-index: 99;
        padding: 0;
    }
}

.mobile-nav {
    display: block;

    @include breakpoint(large) {
        display: none;
    }
}