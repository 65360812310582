// Icons
@include font-face('FontAwesome',
    'fontawesome-webfont.woff',
    'fontawesome-webfont.ttf',
    'fontawesome-webfont.svg',
    'fontawesome-webfont.eot'
);

@font-face {
  font-family: 'Ubuntu';
  font-style: italic;
  font-weight: 300;
  src: local('Ubuntu Light Italic'), local('Ubuntu-LightItalic'), url(/_fgf/s/ubuntu/v11/4iCp6KVjbNBYlgoKejZftVyPN4E.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
} 


@font-face {
  font-family: 'Ubuntu';
  font-style: italic;
  font-weight: 400;
  src: local('Ubuntu Italic'), local('Ubuntu-Italic'), url(/_fgf/s/ubuntu/v11/4iCu6KVjbNBYlgoKej70l0k.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
} 


@font-face {
  font-family: 'Ubuntu';
  font-style: italic;
  font-weight: 500;
  src: local('Ubuntu Medium Italic'), local('Ubuntu-MediumItalic'), url(/_fgf/s/ubuntu/v11/4iCp6KVjbNBYlgoKejYHtFyPN4E.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
} 


@font-face {
  font-family: 'Ubuntu';
  font-style: italic;
  font-weight: 700;
  src: local('Ubuntu Bold Italic'), local('Ubuntu-BoldItalic'), url(/_fgf/s/ubuntu/v11/4iCp6KVjbNBYlgoKejZPslyPN4E.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
} 


@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 300;
  src: local('Ubuntu Light'), local('Ubuntu-Light'), url(/_fgf/s/ubuntu/v11/4iCv6KVjbNBYlgoC1CzjsGyN.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
} 


@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 400;
  src: local('Ubuntu Regular'), local('Ubuntu-Regular'), url(/_fgf/s/ubuntu/v11/4iCs6KVjbNBYlgoKfw72.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
} 


@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 500;
  src: local('Ubuntu Medium'), local('Ubuntu-Medium'), url(/_fgf/s/ubuntu/v11/4iCv6KVjbNBYlgoCjC3jsGyN.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
} 


@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 700;
  src: local('Ubuntu Bold'), local('Ubuntu-Bold'), url(/_fgf/s/ubuntu/v11/4iCv6KVjbNBYlgoCxCvjsGyN.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
} 
@font-face {
  font-family: 'Ubuntu';
  font-style: italic;
  font-weight: 300;
  src: local('Ubuntu Light Italic'), local('Ubuntu-LightItalic'), url(/_fgf/s/ubuntu/v11/4iCp6KVjbNBYlgoKejZftVyPN4c.woff) format('woff');
}
@font-face {
  font-family: 'Ubuntu';
  font-style: italic;
  font-weight: 400;
  src: local('Ubuntu Italic'), local('Ubuntu-Italic'), url(/_fgf/s/ubuntu/v11/4iCu6KVjbNBYlgoKej70l08.woff) format('woff');
}
@font-face {
  font-family: 'Ubuntu';
  font-style: italic;
  font-weight: 500;
  src: local('Ubuntu Medium Italic'), local('Ubuntu-MediumItalic'), url(/_fgf/s/ubuntu/v11/4iCp6KVjbNBYlgoKejYHtFyPN4c.woff) format('woff');
}
@font-face {
  font-family: 'Ubuntu';
  font-style: italic;
  font-weight: 700;
  src: local('Ubuntu Bold Italic'), local('Ubuntu-BoldItalic'), url(/_fgf/s/ubuntu/v11/4iCp6KVjbNBYlgoKejZPslyPN4c.woff) format('woff');
}
@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 300;
  src: local('Ubuntu Light'), local('Ubuntu-Light'), url(/_fgf/s/ubuntu/v11/4iCv6KVjbNBYlgoC1CzjsGyL.woff) format('woff');
}
@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 400;
  src: local('Ubuntu Regular'), local('Ubuntu-Regular'), url(/_fgf/s/ubuntu/v11/4iCs6KVjbNBYlgoKfw7w.woff) format('woff');
}
@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 500;
  src: local('Ubuntu Medium'), local('Ubuntu-Medium'), url(/_fgf/s/ubuntu/v11/4iCv6KVjbNBYlgoCjC3jsGyL.woff) format('woff');
}
@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 700;
  src: local('Ubuntu Bold'), local('Ubuntu-Bold'), url(/_fgf/s/ubuntu/v11/4iCv6KVjbNBYlgoCxCvjsGyL.woff) format('woff');
}
@font-face {
  font-family: 'Ubuntu';
  font-style: italic;
  font-weight: 300;
  src: local('Ubuntu Light Italic'), local('Ubuntu-LightItalic'), url(/_fgf/s/ubuntu/v11/4iCp6KVjbNBYlgoKejZftVyPN4Q.ttf) format('truetype');
}
@font-face {
  font-family: 'Ubuntu';
  font-style: italic;
  font-weight: 400;
  src: local('Ubuntu Italic'), local('Ubuntu-Italic'), url(/_fgf/s/ubuntu/v11/4iCu6KVjbNBYlgoKej70l0w.ttf) format('truetype');
}
@font-face {
  font-family: 'Ubuntu';
  font-style: italic;
  font-weight: 500;
  src: local('Ubuntu Medium Italic'), local('Ubuntu-MediumItalic'), url(/_fgf/s/ubuntu/v11/4iCp6KVjbNBYlgoKejYHtFyPN4Q.ttf) format('truetype');
}
@font-face {
  font-family: 'Ubuntu';
  font-style: italic;
  font-weight: 700;
  src: local('Ubuntu Bold Italic'), local('Ubuntu-BoldItalic'), url(/_fgf/s/ubuntu/v11/4iCp6KVjbNBYlgoKejZPslyPN4Q.ttf) format('truetype');
}
@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 300;
  src: local('Ubuntu Light'), local('Ubuntu-Light'), url(/_fgf/s/ubuntu/v11/4iCv6KVjbNBYlgoC1CzjsGyI.ttf) format('truetype');
}
@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 400;
  src: local('Ubuntu Regular'), local('Ubuntu-Regular'), url(/_fgf/s/ubuntu/v11/4iCs6KVjbNBYlgoKfw7z.ttf) format('truetype');
}
@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 500;
  src: local('Ubuntu Medium'), local('Ubuntu-Medium'), url(/_fgf/s/ubuntu/v11/4iCv6KVjbNBYlgoCjC3jsGyI.ttf) format('truetype');
}
@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 700;
  src: local('Ubuntu Bold'), local('Ubuntu-Bold'), url(/_fgf/s/ubuntu/v11/4iCv6KVjbNBYlgoCxCvjsGyI.ttf) format('truetype');
}
